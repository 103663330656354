.form-message.gray {
  border: 1px solid var(--gray-100);
  color: var(--gray-800);
  background: var(--gray-100);
}
.form-message.purple {
  border: 1px solid var(--purple-100);
  color: var(--purple-800);
  background: var(--purple-100);
}
.form-message.pink {
  border: 1px solid var(--pink-100);
  color: var(--pink-800);
  background: var(--pink-100);
}

textarea.form-control[data-fieldtype="Small Text"][data-doctype=Parcel] {
  height: auto;
  background: red;
}
textarea.form-control[data-fieldtype="Small Text"][data-doctype="Parcel Content"] {
  height: auto;
  background: red;
}
textarea.form-control[data-fieldtype="Small Text"][data-doctype="Warehouse Receipt"] {
  height: auto;
  background: red;
}
textarea.form-control[data-fieldtype="Small Text"][data-doctype="Cargo Shipment Receipt Line"] {
  height: auto;
  background: red;
}

div.frappe-control[data-fieldtype=TransportationMultiCheck] .checkbox.unit-checkbox {
  border-radius: 5px;
}
div.frappe-control[data-fieldtype=TransportationMultiCheck] .checkbox.unit-checkbox input {
  display: none;
}
div.frappe-control[data-fieldtype=TransportationMultiCheck] .checkbox.unit-checkbox label {
  display: block;
}
div.frappe-control[data-fieldtype=TransportationMultiCheck] .checkbox.unit-checkbox label .label-area {
  color: currentColor;
}
div.frappe-control[data-fieldtype=TransportationMultiCheck] .checkbox.unit-checkbox label:has(input:checked) {
  background-color: currentColor;
}
div.frappe-control[data-fieldtype=TransportationMultiCheck] .checkbox.unit-checkbox label:has(input:checked) .label-area {
  color: white !important;
}